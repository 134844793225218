// MUI IMPORTS
import { ListItemIcon } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useState } from 'react';

type Props = {

}


function SideNav({
}: Props) {

    const drawerWidth = 240;
    return (
        <Drawer
            variant="permanent"
            sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            anchor="left"
            open={true}
        >
            <List>

                {/* <ListItem key={'Users'} disablePadding>
                    <ListItemButton
                        onClick={() => {
                            window.location.replace('/users');
                        }}
                    >
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary={'Users'} />
                    </ListItemButton>
                </ListItem> */}

                <ListItem key={'Analytics'} disablePadding>
                    <ListItemButton
                        onClick={() => {
                            window.location.replace('/analytics');
                        }}
                    >
                        <ListItemIcon>
                            {/* <BuildIcon /> */}
                        </ListItemIcon>
                        <ListItemText primary={'Analytics'} />
                    </ListItemButton>
                </ListItem>


                {/* <ListItem key={'Sign Out'} disablePadding>
                    <ListItemButton
                        
                        onClick={() => {
                            window.location.replace('/');
                        }}
                    >
                        <ListItemIcon>
                        </ListItemIcon>
                        <ListItemText primary={'Sign Out'} />
                    </ListItemButton>
                </ListItem> */}
            </List>

        </Drawer>
    )
}

// const mapStateToProps = (rootState: RootState) => {
//     const { theme, alertMsg } = rootState.admin;
//     return {
//         theme,
//         alertMsg
//     }
// }

// const mapDispatch = {
//     toggleTheme: (theme: string) => toggleTheme(theme),
//     setAlert: (alert: AlertMsg) => setAlert(alert),

// }

// export default connect(mapStateToProps, mapDispatch)(SideNav);
export default SideNav;

