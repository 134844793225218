import { TextField } from "@mui/material";
import { FeedbackGridRow } from "../redux/users/interfaces";
import PageTitle from "../resusables/PageTitle";

type Props = {
    feedbackRow: FeedbackGridRow,
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}


function ViewFeedBack({
    feedbackRow,
    open,
    setOpen,
}: Props) {
    return (
        <div className="p-2 w-full">

            <PageTitle title={`Full Feedback Row`} />

            <TextField
                className="my-3"
                fullWidth
                required
                label="Feedback ID"
                value={feedbackRow.id}
                disabled
            />
            <TextField
                className="my-3"
                fullWidth
                required
                label="Feedback"
                value={feedbackRow.complaint}
                disabled
            />
            <TextField
                className="my-3"
                fullWidth
                required
                label="Feedback Creation Time"
                value={feedbackRow.createdAt}
                disabled
            />
            <TextField
                className="my-3"
                fullWidth
                required
                label="Feedback Liked"
                value={feedbackRow.liked}
                disabled
            />
            <TextField
                className="my-3"
                fullWidth
                required
                label="Feedback Prompt"
                value={feedbackRow.prompt}
                disabled
            />
            <TextField
                className="my-3"
                fullWidth
                required
                label="Feedback Response"
                value={feedbackRow.response}
                disabled
            />
        </div>
    )
}
export default ViewFeedBack 