import {
  ACTIONS,
  AnalyticsInterface,
  FeedbackGridRow,
  FeedbackInterface,
  UserGridRow,
  UserInterface,
} from "./interfaces";

// DATABASE CRUD ACTION
export function getAllUsers(
  page: number,
  limit: number,
  sortBy: string,
  dir: string,
  filterBy: string,
  dataFiltered: string
) {
  return {
    type: ACTIONS.GET_ALL_USERS_IN_DB,
    payload: { page, limit, sortBy, dir, filterBy, dataFiltered },
  };
}

export function getAnalytics(
  startAfter: string,
  order: string
) {
  return {
    type: ACTIONS.GET_ANALYTICS_FROM_DB,
    payload: {  startAfter ,order },
  };
}

// SAGA ACTIONS
export function setUsers(
  users: UserInterface[],
  gridRows: UserGridRow[],
  length: number,
  total: number
) {
  return {
    type: ACTIONS.SET_USERS,
    payload: { users, gridRows, length, total },
  };
}

export function setAnalytics(
  feedbackLike: number,
  feedbackDislike: number,
  feedbackInterfaces: FeedbackInterface[],
  feedbackGridRows: FeedbackGridRow[],
  length: number,
  total: number
) {
  return {
    type: ACTIONS.SET_ANALYTICS,
    payload: {
      feedbackLike,
      feedbackDislike,
      feedbackInterfaces,
      feedbackGridRows,
      length,
      total,
    },
  };
}
