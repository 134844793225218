import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid/Grid";
import Paper from "@mui/material/Paper/Paper";
import TextField from "@mui/material/TextField/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import BreadCrumb from "../components/BreadCrumb";
import { ReduxAction, RootState } from "../redux/rootReducer";
import { feedbackColumns, UsersState } from "../redux/users/interfaces";
import { getAnalytics } from "../redux/users/usersActions";
import GridTable, { Pagination } from "../resusables/GridTable";
import PageTitle from "../resusables/PageTitle";
import SideNav from "./SideNav";
type Props = {
    feedbackGridRows: UsersState["analytics"]["feedbackGridRows"],
    total: UsersState["analytics"]["total"],
    getAnalytics: (startAfter: string, order: string) => ReduxAction
}

function Analytics({
    feedbackGridRows,
    total,
    getAnalytics
}: Props) {

    const [showUI, setShowUI] = useState(false);
    let order = 'desc';
    useEffect(() => {
        getAnalytics("", 'desc');
        setShowUI(true);
    }, [])


    const dispatch = useDispatch();

    const handlePagination = (pagination: Pagination) => {
        const startAfter = pagination.startAfter;
        setShowUI(false);
        dispatch(getAnalytics(startAfter, order));
        setShowUI(true);
    }

    const handleGetAnalytics = () => {
        if (order === "desc")
            order = 'asc'
        else order = "desc";
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <SideNav />
            </Grid>
            <Grid item xs={9} sx={{ height: '100%' }}>
                <div className="container flex flex-col h-screen max-h-screen">
                    <BreadCrumb />
                    <PageTitle title="Pic Answer Feedbacks" />
                    <Paper className="p-3">


                        <div className="flex flex-row flex-grow mt-1">
                            <Button
                                variant="outlined"
                                color="primary"
                                className="m-2"
                                disableElevation
                                onClick={() => handleGetAnalytics()}
                            >
                                Reverse Order
                            </Button>
                        </div>
                        {showUI ?
                            <GridTable
                                columns={feedbackColumns}
                                rows={feedbackGridRows}
                                total={total}
                                length={50}
                                usedFor="pic-answer"
                                handlePagination={handlePagination}
                            /> : null}
                    </Paper>



                </div>
            </Grid>
        </Grid>
    )
}


const mapStateToProps = (rootState: RootState) => {
    const { total, feedbackGridRows, feedbackDislikes, feedbackLikes, feedbackInterfaces } = rootState.users.analytics;
    return {
        total, feedbackGridRows, feedbackDislikes, feedbackLikes, feedbackInterfaces
    }
}

const mapDispatch = {
    getAnalytics: (startAfter: string, order: string,) => getAnalytics(startAfter, order),
}
export default connect(mapStateToProps, mapDispatch)(Analytics)