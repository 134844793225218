import { Column } from "../../resusables/GridTable";

export const ACTIONS = {
  GET_ALL_USERS_IN_DB: "GET_ALL_USERS_IN_DB",
  GET_ANALYTICS_FROM_DB: "GET_ANALYTICS_FROM_DB",
  SET_USERS: "SET_USERS",
  SET_ANALYTICS: "SET_ANALYTICS",
};

export const USERS_INITIAL_STATE: UsersState = {
  users: [],
  gridRows: [],
  total: 0,
  length: 0,
  analytics: {
    feedbackLikes: 0,
    feedbackDislikes: 0,
    total: 0,
    length: 0,
    feedbackInterfaces: [],
    feedbackGridRows: [],
  },
};

export interface UsersState {
  users: UserInterface[];
  gridRows: UserGridRow[];
  total: number;
  length: number;
  analytics: AnalyticsInterface;
}

export interface UserInterface {
  _id: string;
  first: string;
  last: string;
  email: string;
  platform: string;
  userId: string;
  deviceId: string;
  credits: number;
  isGuest: boolean;
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
  invitationCode: string;
}

export interface UserGridRow {
  _id: string;
  first: string;
  last: string;
  email: string;
  platform: string;
  credits: number;
  isGuest: string;
  createdAt: string;
  invitationCode: string;
}

export interface AnalyticsInterface {
  feedbackLikes: number;
  feedbackDislikes: number;
  total: number;
  length: number;
  feedbackInterfaces: FeedbackInterface[];
  feedbackGridRows: FeedbackGridRow[];
}

export interface FeedbackInterface {
  id: string;
  updatedBy: string;
  feedbackDescription: string;
  response: string;
  prompt: string;
  isPositiveFeedback: boolean;
}

export interface FeedbackGridRow {
  id: string;
  createdAt: string;
  complaint: string;
  liked: boolean;
  response: string;
  prompt: string;
}

export function createFeedbackData(f: FeedbackInterface): FeedbackGridRow {
  return {
    id: f.id,
    createdAt: f.updatedBy,
    complaint: f.feedbackDescription,
    liked: f.isPositiveFeedback,
    response: f.response,
    prompt: f.prompt,
  };
}

export function formatFeedbackGridRows(
  ff: FeedbackInterface[]
): FeedbackGridRow[] {
  return ff.map((f) => createFeedbackData(f));
}

export function createData(userInterface: UserInterface): UserGridRow {
  return {
    _id: userInterface._id,
    first: userInterface.first,
    last: userInterface.last,
    email: userInterface.email,
    platform: userInterface.platform,
    credits: userInterface.credits,
    isGuest: "" + userInterface.isGuest,
    createdAt: userInterface.createdAt,
    invitationCode: userInterface.invitationCode,
  };
}

export function formatUsersGridRows(users: UserInterface[]): UserGridRow[] {
  return users.map((user) => createData(user));
}

// COLUMNS

export const userColumns: Column[] = [
  { id: "first", label: "First", minWidth: 75 },
  { id: "last", label: "Last", minWidth: 75 },
  { id: "platform", label: "Platform", minWidth: 75 },
  { id: "isGuest", label: "Guest", minWidth: 75 },
  { id: "credits", label: "Credits", minWidth: 50 },
  { id: "invitationCode", label: "Invitation Code", minWidth: 50 },
  { id: "email", label: "Email", minWidth: 150 },
  {
    id: "createdAt",
    label: "Date Created",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];

export const feedbackColumns: Column[] = [
  { id: "complaint", label: "Complaint", minWidth: 250 },
  {
    id: "prompt",
    label: "Prompt",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "response",
    label: "Response",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
];
