import { act } from "react-dom/test-utils";
import { ReduxAction } from "../rootReducer";
import { ACTIONS, UsersState, USERS_INITIAL_STATE } from "./interfaces";

export const usersReducer = (
  state: UsersState = USERS_INITIAL_STATE,
  action: ReduxAction
): UsersState => {
  switch (action.type) {
    // SAGA ACTIONS ------------------------------------------------------------------------
    case ACTIONS.SET_USERS:
      return {
        ...state,
        users: action.payload.users,
        gridRows: action.payload.gridRows,
        length: action.payload.length,
        total: action.payload.total,
      };
    case ACTIONS.SET_ANALYTICS:
      return {
        ...state,
        analytics: {
          total: action.payload.total,
          length: action.payload.length,
          feedbackLikes: action.payload.feedbackLike,
          feedbackDislikes: action.payload.feedbackDislike,
          feedbackInterfaces: action.payload.feedbackInterfaces,
          feedbackGridRows: action.payload.feedbackGridRows,
        },
      };
    default:
      return { ...state };
  }
};
