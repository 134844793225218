import {
  getAllUsers,
  getAnalytics,
  setAnalytics,
  setUsers,
} from "./usersActions";
import { all, call, put, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import {
  ACTIONS,
  AnalyticsInterface,
  FeedbackGridRow,
  FeedbackInterface,
  formatFeedbackGridRows,
  formatUsersGridRows,
  UserInterface,
} from "./interfaces";

// GET ALL Users
type GetAllUsersResponse = {
  status: number;
  message: string;
  data: {
    total: number;
    length: number;
    data: UserInterface[];
  };
};
const _URL = "https://us-central1-ai-photosolve.cloudfunctions.net";
type GetAllUsersInDbAction = ReturnType<typeof getAllUsers>;
function* handleGetAllUsersInDb({ payload }: GetAllUsersInDbAction) {
  try {
    const page = payload.page;
    const limit = payload.limit;
    const dir = payload.dir;
    const sortBy = payload.sortBy;
    const filterBy = payload.filterBy;
    const dataFiltered = payload.dataFiltered;
    let url = `/api/v1/users?page=${page}&limit=${limit}`;
    if (sortBy.length > 0) {
      url += `&dir=${dir}&sort=${sortBy}`;
    }

    if (filterBy && dataFiltered) {
      url += `&${filterBy}=${dataFiltered}`;
    }
    const res: AxiosResponse<GetAllUsersResponse> = yield call(axios.get, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { status, message, data } = res.data;
    if (status !== 200) throw new Error(message);
    const users = data.data;
    const total = data.total;
    const length = data.length;
    const gridRows = formatUsersGridRows(users);
    yield put(setUsers(users, gridRows, length, total));
    // yield put(
    //   setAlert({
    //     msg: 'Projects Fetched Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (err: any) {
    // yield put(
    //   setAlert({
    //     msg: err.message,
    //     config: ERR_SNACKBAR,
    //   })
    // );
  }
}

function* interceptGetUsersInDb() {
  yield takeLatest([ACTIONS.GET_ALL_USERS_IN_DB], handleGetAllUsersInDb);
}

type GetAnalyticsResponse = {
  feedbackLike: number;
  feedbackDislike: number;
  total: number;
  dislikes: FeedbackInterface [];
};

type GetAnalyticsInDbAction = ReturnType<typeof getAnalytics>;
function* handleGetAnalyticsInDb({ payload }: GetAnalyticsInDbAction) {
  try {
    const startAfter = payload.startAfter;
    const order = payload.order;
    let url = _URL + `/apiFeedbackCounts?`;
    
    if (startAfter) {
      url += `startAfter=${startAfter}&`;
    }
    if (order) {
      url += `order=${order}&`;
    }
    const res: AxiosResponse<GetAnalyticsResponse> = yield call(axios.get, url);
    if (!res) throw new Error("NO RESULT RETURNED FROM API");
    const { feedbackLike, feedbackDislike, dislikes, total } = res.data;
   const feedbackGridRows : FeedbackGridRow [] = formatFeedbackGridRows(dislikes);
    yield put(setAnalytics(feedbackLike, feedbackDislike, dislikes, feedbackGridRows, dislikes.length, total));
    
    // yield put(
    //   setAlert({
    //     msg: 'Projects Fetched Successfully',
    //     config: SUCCESS_SNACKBAR,
    //   })
    // );
  } catch (err: any) {
    // yield put(
    //   setAlert({
    //     msg: err.message,
    //     config: ERR_SNACKBAR,
    //   })
    // );
  }
}
function* interceptGetAnalyticsInDb() {
  yield takeLatest([ACTIONS.GET_ANALYTICS_FROM_DB], handleGetAnalyticsInDb);
}

export function* usersSagas() {
  yield all([call(interceptGetUsersInDb), call(interceptGetAnalyticsInDb)]);
}
