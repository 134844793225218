import { IconButton, TableSortLabel, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import ViewFeedBack from '../components/ViewFeedBack';
import { FeedbackGridRow, UserGridRow } from '../redux/users/interfaces';
import CustomModal from './CustomModal/CustomModal';
export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left';
  format?: (value: number) => string;
}

export interface Pagination {
  startAfter: string
}



type Props = {
  columns: Column[],
  rows: UserGridRow[] | FeedbackGridRow[],
  usedFor: string,
  total: number,
  length: number
  handlePagination: (pagination: Pagination) => void
}


type Direction = "desc" | "asc" | undefined;
function GridTable({ columns,
  rows,
  total,
  length,
  usedFor,
  handlePagination
}:
  Props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [feedbackRow, setFeedbackRow] = useState<FeedbackGridRow>({
    id: "",
    createdAt: "",
    complaint: "",
    liked: false,
    response: "",
    prompt: "",
  });
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [paginationRow, setPaginationRow] = useState(50);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState<Direction>('desc');



  const handleChangePage = (event: unknown, newPage: number) => {
    
    const row = (rows[rows.length -1]) as FeedbackGridRow;
    const pagination: Pagination = {
      startAfter: row.id
    }
    handlePagination(pagination)
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Retrieve the new value of rows per page from the event target
    let updatedRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(updatedRowsPerPage);
    setPage(0);

    // Update the state with the new value
    setRowsPerPage(updatedRowsPerPage);
    const row = (rows[rows.length -1]) as FeedbackGridRow;
    const pagination: Pagination = {
      startAfter: row.id
    }
    handlePagination(pagination);
  };

  const handleClick = (feedbackRow: FeedbackGridRow) => {
    if (usedFor === "pic-answer") {
      setOpen(true); setFeedbackRow(feedbackRow);
    }
  }

  // const handleSorting = (sortBy: string) => {
  //   if (order === 'asc') {
  //     setOrder('desc')
  //   } else {
  //     setOrder('asc')
  //   }

  //   setPage(0);

  //   const pagination: Pagination = {
  //   }
  //   // handlePagination(pagination);

  // }

  // const handleFiltering = (filterBy: string, dataFiltered: string) => {

  //   if (order === 'asc') {
  //     setOrder('desc')
  //   } else {
  //     setOrder('asc')
  //   }

  //   setPage(0);
  //   const pagination: Pagination = {
  //   }
  //   // handlePagination(pagination);
  // }


  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0}>
        <TableContainer sx={{ maxHeight: 700 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {/* <TableSortLabel
                      active={orderBy === column.id}
                      // direction={orderBy === column.id ? order : 'asc'}
                      direction={order}
                      onClick={() => handleSorting(column.id)}
                    > */}
                    {/* <TextField
                        key={column.id}
                        label={column.label}
                        variant="standard"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                          handleFiltering(column.id,event.target.value)
                        }
                      /> */}
                    {column.label}
                    {/* </TableSortLabel> */}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .map((row, idx) => {
                  return (
                    <TableRow
                      hover tabIndex={-1}
                      key={idx}
                    >
                      {columns.map((column) => {
                        const anyRow = row as any
                        const value = anyRow[column.id];
                        const feedbackRow = row as FeedbackGridRow;
                        return (
                          <TableCell
                            onClick={() => handleClick(feedbackRow)}
                            key={column.id} align={column.align}>
                            {value}
                          </TableCell>

                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <CustomModal open={open} setOpen={setOpen}>
        <ViewFeedBack
          feedbackRow={feedbackRow}
          open={open} setOpen={setOpen} />
      </CustomModal>
    </div>

  );

}


export default GridTable;

