import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { ReduxAction } from "../../redux/rootReducer";
import './CustomModal.css';


type Props = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>> | ((b: boolean) => ReduxAction),
    children: ReactJSXElement,
}

function CustomModal({
    open,
    setOpen,
    children,
}: Props) {
    return (
        <Modal
            // disablePortal={true}
            // disableEnforceFocus={true}
            // disableAutoFocus={true}
            open={open}
            onClose={() => setOpen(false)}>
            <div className='modal' style={{ backgroundColor: 'white' }}>
                {children}
            </div>
        </Modal>
    )
}


export default CustomModal;