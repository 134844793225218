import {
  Route, Routes
} from "react-router-dom";
import Analytics from './pages/Analytics';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Routes>

      {/* <Route path="/" element={<Login />} />
      <Route path="/users" element={<Users />} /> */}
      <Route path="/analytics" element={<Analytics />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;
